<template>
  <transition name="fade">
    <ApiErrorDialog v-if="api.isError" :api="api" />
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="360"
      class="rounded-xl"
      scrollable
    >
      <v-card class="pb-5" rounded="lg">
        <v-card-title class="justify-center text-h5">
          {{ $t("string.levelUp") }}
        </v-card-title>
        <AbstractAvatar
          :avatar="auth.Player.avatar"
          :height="100"
          :width="120"
          class="mx-auto d-flex align-center justify-center mb-2"
        ></AbstractAvatar>
        <v-card-text class="text-center">
          <div class="d-flex justify-center align-center">
            <span class="gray--text">{{ $t("string.avatarLevel") }} {{ level - 1 }}</span>
            <v-icon class="mx-2"> mdi-arrow-right-bold </v-icon>
            <span class="success--text text-h3">{{ level }}</span>
          </div>
        </v-card-text>
        <div class="text-center mt-3 mb-1">{{ $t("model.prop.rewards") }}</div>
        <Item
          :imageUrl="require('@/assets/explore/coin.png')"
          :count="10"
          width="30"
          :isShowCount="true"
          style="width: 65px"
          class="mx-auto mt-2"
        />
        
        <v-btn
          @click="callbackClose"
          class="text-h6 mt-6 mx-auto"
          color="yellow"
          :loading="api.isLoading"
          large
          rounded
        >
          OK
        </v-btn>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    AbstractAvatar: () =>
      import(
        /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
      ),
    Item: () =>
      import(
        /* webpackChunkName: "component-mission-reward-item" */ "@/components/shop/Item.vue"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  data: () => ({
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
  }),
  async mounted() {
    await this.addCoin(10, "goldcoin");
  },
  props: ["showDialog", "callbackClose", "level"],
  methods: {
    async addCoin(q, type) {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/item/updateMany";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
        console.error(e);
      };

      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
      };
      this.api.params = {
        data: `[{"action":"add","itemKey":"${type}","quantity": ${q}}]`,
      };
      await this.$api.fetch(this.api);
    },
  },
};
</script>
