<template>
  <!-- <transition name="fade"> -->
  <v-dialog v-model="showDialog" persistent max-width="400" class="rounded-xl" scrollable>
    <v-card class="pb-5" rounded="lg">
      <v-card-title class="justify-center text-h5">
        {{ $t("string.gotIt") }}
        <!-- {{ $t("string.levelUp") }} -->
      </v-card-title>
      <!-- <AbstractAvatar :avatar="auth.Player.avatar" :height="100" :width="120"
        class="mx-auto d-flex align-center justify-center mb-2"></AbstractAvatar>
      <v-card-title class="justify-center pt-0 text-h5">{{
        auth.Student ? auth.Student.name : auth.User.name
      }}</v-card-title> -->
      <v-card-text class="text-center">
        <div class="d-flex justify-center">
          <div><v-img :src="to.imageUrl" width="200"></v-img></div>
        </div>
        <div class="text-caption">{{ name }}</div>
        <div class="text-h5 black--text">{{ to.name }}</div>
        <!-- <v-row align="center">
          <v-col cols="5" class="text-h6">
            <v-img :src="from.imageUrl" max-width="100%"></v-img>
            {{ from.name }}
          </v-col>

          <v-col cols="2">
            <v-icon large> mdi-arrow-right-bold </v-icon>
          </v-col>
          <v-col cols="5" class="text-h6">
            <v-img :src="to.imageUrl"></v-img>
            {{ to.name }}
          </v-col>
        </v-row> -->
      </v-card-text>
      <div class="text-center">
        <v-btn @click="callbackClose" class="text-h6" color="yellow" rounded large>
          OK
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
  <!-- </transition> -->
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    // AbstractAvatar: () =>
    //   import(
    //     /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
    //   ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: ["showDialog", "callbackClose", "from", "to", "name"],
};
</script>
