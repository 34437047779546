<template>
  <transition name="fade">
    <ApiErrorDialog v-if="api.isError" :api="api" />
    <LevelUpXPDialog
      v-if="showLevelUp"
      :callbackClose="exitLevelUp"
      :showDialog="true"
      :level="details.currentLevel"
    />
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="360"
      class="rounded-xl"
      scrollable
    >
      <v-card class="pb-5" rounded="lg">
        <v-card-title class="pt-1 pe-1">
          <v-spacer></v-spacer>
        </v-card-title>
        <AbstractAvatar
          :avatar="auth.Player.avatar"
          :height="100"
          :width="120"
          class="mx-auto d-flex align-center justify-center mb-2"
        ></AbstractAvatar>
        <div class="text-center text-caption">
          {{ auth.Student ? auth.Student.name : auth.User.name }}
        </div>
        <div class="text-h6 text-center">
          {{ $t("string.avatarLevel") }}
          {{
            details.isLevelUp ? details.currentLevel - 1 : details.currentLevel
          }}
        </div>
        <v-progress-linear
          :value="(currentXp / finalXp) * 100"
          striped
          color="amber"
          rounded
          height="30"
          class="mx-auto mb-3"
          style="max-width: 200px"
        >
          {{ currentXp + " / " + finalXp + " XP" }}
        </v-progress-linear>

        <div v-if="details.isLevelUp" class="text-h2 text-center my-3">
          + {{ details.xp }}XP
        </div>
        <v-btn
          @click="callbackClose"
          class="text-h6 mt-6 mx-auto"
          color="yellow"
          :loading="api.isLoading"
          rounded
          large
        >
          OK
        </v-btn>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    AbstractAvatar: () =>
      import(
        /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    LevelUpXPDialog: () =>
      import(
        /* webpackChunkName: "component-level-up-dialog" */ "@/components/LevelUpXPDialog.vue"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  data: () => ({
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    currentXp: 0,
    finalXp: 0,
    showLevelUp: false,
  }),
  async mounted() {
    this.currentXp = this.details.currentXp - this.details.xp;
    this.finalXp = this.details.isLevelUp
      ? this.details.currentXp
      : this.details.nextXpThreshold;
    setTimeout(() => {
      this.currentXp = this.details.currentXp;
      if (this.details.isLevelUp)
        setTimeout(() => {
          this.showLevelUp = true;
        }, 1000);
    }, 1000);
  },
  props: ["showDialog", "callbackClose", "details"],
  methods: {
    exitLevelUp() {
      this.details.isLevelUp = false;
      this.finalXp = this.details.nextXpThreshold;
      this.showLevelUp = false;
      this.callbackClose();
    },
  },
};
</script>
